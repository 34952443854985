import { Container, Grid, Typography } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import CheckList from "../components/CheckList";
import EnquiryForm from "../components/EnquiryForm";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  commercialMortgagePageHeroContainer: {
    backgroundColor: orange[50]
  },
  commercialMortgagePageTitle: {
    marginBottom: theme.spacing(2),
    wordBreak: "break-word",
    [theme.breakpoints.only("xs")]: {
      fontSize: "3rem"
    }
  }
}));

const CommercialMortgagePage = () => {
  const classes = useStyles();
  const title = "Commercial Mortgage Quote";
  const description =
    "Let our experts give you an idea on the interest rates you should expect, and advise on the best funding strategies.";

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Section className={classes.commercialMortgagePageHeroContainer}>
        <Container>
          <Grid container>
            <Grid item xs={12} lg={10}>
              <Typography
                className={classes.commercialMortgagePageTitle}
                variant="h1"
              >
                {title}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {description}
              </Typography>
              <CheckList
                points={[
                  "Specialising in sourcing funding for commercial property",
                  "Access to over 200 lenders",
                  "No upfront fees"
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <EnquiryForm
                type="COMMERCIAL_MORTGAGE"
                steps={[
                  "Your Details",
                  "Business Details",
                  "Property Details",
                  "Mortgage Details"
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default CommercialMortgagePage;
